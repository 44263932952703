<template>
    <div class="details">
        <div class="top">
            <img src="@/assets/img/lookWages.png" alt="">
            <div>{{details.detail_name}}</div>
        </div>
        <div class="content">
            <div class="cell">
                <div>发放时间</div>
                <div>{{details.loan_time}}</div>
            </div>
            <div class="cell">
                <div>工资总额</div>
                <div>{{details.total_pay}}</div>
            </div>
            <div class="cell">
                <div>借支冲销</div>
                <div>{{details.reverse_pay}}</div>
            </div>
            <div class="cell">
                <div>实发工资</div>
                <div>{{details.actual_pay}}</div>
            </div>
            <div class="cell" v-for="(item, index) in details.extra_data_json" :key="index">
                <div>{{item.key}}</div>
                <div>{{item.value}}</div>
            </div>
        </div>
        <div class="bottom_back">
            <div class="bottom_back_on" @click="onClickLeft">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            details: {}
        }
    },
    created() {
        this.details = JSON.parse(sessionStorage.getItem('lookWages'))
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
    }
}
</script>
<style scoped>
.details{
    min-height: calc(100vh - 24px);
    background-color: #f8f8f8;
}
.top {
    background-color: #fff;
    display: flex;
    padding: 30px;
    align-items: center;
    color: #1a1a1a;
    font-weight: 700;
    margin-top: 24px;
}
.top img {
    width: 70px;
    height: 70px;
    margin-right: 30px;
}
.content {
    background: #fff;
    padding: 0 30px;
    margin-top: 24px;
}
.content .cell {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f8f8f8;
    padding: 30px 0;
}
.content .cell:last-of-type {
    border: 0;
}
</style>